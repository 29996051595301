import React from "react";

import "./PoweredByStripe.css";
import stripeTrustBadge from "../Assets/stripe/stripe-badge-transparent.png";

const PoweredByStripe = () => {
  return (
    <div>
      <div className="poweredByStripe">
        <img src={stripeTrustBadge} alt="powered by Stripe" />
      </div>
    </div>
  );
};

export default PoweredByStripe;
