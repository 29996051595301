import React from "react";

import { checkoutText } from '../DataFiles/standardText';
import CheckoutFormContainer from './CheckoutFormContainer';

import "../MainForm/MainForm.css";

const SimpleSubmit = (props) => {
  const { formData, onPageUpdate, currentScreen } = props;

  const apiKey =
    process.env.REACT_APP_STAGE === 'prod'
      ? 're4JhBAsBwYoe2cF5asg'
      : '1ct3WDh93LggV92G4i3H';

  return (
    <div className='checkoutContainer'>
      <div className='checkoutFormImage'></div>
      <div className='checkoutForm'>
        <div
          className='messageHeader'
          dangerouslySetInnerHTML={{ __html: checkoutText.header }}
        />
        <CheckoutFormContainer
          apiKey={apiKey}
          formData={formData}
          onPageUpdate={onPageUpdate}
          currentScreen={currentScreen}
        />
      </div>
    </div>
  );
};

export default SimpleSubmit;
