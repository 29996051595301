export const heightRange = [
  `3'0"`,
  `3'1"`,
  `3'2"`,
  `3'3"`,
  `3'4"`,
  `3'5"`,
  `3'6"`,
  `3'7"`,
  `3'8"`,
  `3'9"`,
  `3'10"`,
  `3'11"`,
  `4'0"`,
  `4'1"`,
  `4'2"`,
  `4'3"`,
  `4'4"`,
  `4'5"`,
  `4'6"`,
  `4'7"`,
  `4'8"`,
  `4'9"`,
  `4'10"`,
  `4'11"`,
  `5'0"`,
  `5'1"`,
  `5'2"`,
  `5'3"`,
  `5'4"`,
  `5'5"`,
  `5'6"`,
  `5'7"`,
  `5'8"`,
  `5'9"`,
  `5'10"`,
  `5'11"`,
  `6'0"`,
  `6'1"`,
  `6'2"`,
  `6'3"`,
  `6'4"`,
  `6'5"`,
  `6'6"`,
  `6'7"`,
  `6'8"`,
  `6'9"`,
  `6'10"`,
  `6'11"`,
  `7'0"`,
  `7'1"`,
  `7'2"`,
  `7'3"`,
  `7'4"`,
  `7'5"`,
  `7'6"`,
  `7'7"`,
  `7'8"`,
  `7'9"`,
  `7'10"`,
  `7'11"`,
  `8'0"`,
  `8'1"`,
  `8'2"`,
  `8'3"`,
  `8'4"`,
  `8'5"`,
  `8'6"`,
  `8'7"`,
  `8'8"`,
  `8'9"`,
  `8'10"`,
  `8'11"`,
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const dates = () => {
  const datesArray = [];
  for (let i = 1; i < 32; i++) {
    datesArray.push(i);
  }
  return datesArray;
};

export const years = (startYear) => {
  const currentYear = new Date().getFullYear();
  const years = [];
  startYear = startYear || 1930;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
};




export const questionnaire = [
  {
    question: "Please select your sex assigned at birth",
    answers: [
      {
        answer: "Female",
      },
      {
        answer: "Male",
      },
      {
        answer: "Other",
      },
    ],
    type: "radio",
    standardDemo: "sex",
  },
  {
    question: "What state are you in?",
    answers: ["states"],
    standardDemo: "state",
    type: "select",
  },
  {
    question:
      "Have you been diagnosed with or treated for any of the following conditions within the past year? Select all that apply.",
    answers: [
      {
        answer: "Sexually transmitted infections (STIs)",
        showConditional: false,
      },
      {
        answer: "HIV/AIDS",
        showConditional: false,
      },
      {
        answer: "Liver disease",
        showConditional: false,
      },
      {
        answer: "Kidney disease",
        showConditional: false,
      },
      {
        answer: "Asthma",
        showConditional: false,
      },
      {
        answer: "Allergies",
        showConditional: true,
      },
      {
        answer: "Other",
        showConditional: true,
      },
      {
        answer: "None of these apply",
        showConditional: false,
      },
    ],
    type: "checkBox",
    isConditional: false,
  },
  {
    question: "Please tell us more about your diagnoses and treatment(s).",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
    showPreviousAnswers: true,
  },
  {
    question:
      "Are you currently taking any medications, herbal supplements, or over-the-counter drugs including pre-exposure prophylaxis medication for HIV (PrEP)?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question: "Please provide details related to the medications, herbal supplements, or over-the-counter you are currently taking.",
    // subText: "Include name, dosage, effectiveness, and any side effects.",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  {
    question: "Do you have any known allergies to medications or any specific allergies to doxycycline?",
    answers: ["allergies"],
    type: "freeText",
    standardDemo: "allergies",
    isConditional: false,
  },
  {
    question:
      "Have you experienced any of the following symptoms within the past 30 days? Select all that apply.",
    answers: [
      {
        answer: "Unexplained genital sores or ulcers",
        showConditional: false,
      },
      {
        answer: "Painful urination",
        showConditional: false,
      },
      {
        answer: "Abnormal discharge from the genital area",
        showConditional: false,
      },
      {
        answer: "Lower abdominal pain",
        showConditional: false,
      },
      {
        answer: "Swollen lymph nodes",
        showConditional: false,
      },
      {
        answer: "Fever, rash, malaise",
        showConditional: true,
      },
      {
        answer: "None of these apply",
        showConditional: false,
      },
    ],
    type: "checkBox",
    isConditional: false,
  },
  {
    question: "Have you had any recent condomless/unprotected oral, anal or vaginal sex or exposure to people with STIs/who recently got tested positive for STIs?",
    answers: ["freeText"],
    buttonText: "No",
    type: "freeText",
    isConditional: false,
  },
  {
    question:
      "Do you have any other medical conditions or relevant information that you believe we should know?",
    answers: ["freeText"],
    buttonText: "No",
    type: "freeText",
    isConditional: false,
  },
];
