export const alphaMap = {
    intro: 'a',
    0: 'b',
    1: 'c',
    2: 'd',
    3: 'e',
    4: 'f',
    5: 'g',
    6: 'h',
    7: 'i',
    8: 'j',
    9: 'k',
    10: 'l',
    11: 'm',
    12: 'n',
    13: 'o',
    14: 'p',
    15: 'q',
    16: 'r',
    17: 's',
    18: 't',
    photo: 'u',
    checkout: 'v',
    consultSubmitted: 'w'
};

