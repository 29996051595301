import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { config } from './config';

/* this is only for local mock server testing */
// setup: npx msw init public/ --save
// if (process.env.NODE_ENV === "development") {
//   console.log("env", process.env.NODE_ENV);
//   // if (window.location.pathname === "/neurolief") {
//   //   window.location.pathname = "/neurolief/";
//   // }
//   const { worker } = require("./mocks/browser");
//   worker.start({
//     // serviceWorker: {
//     //   url: "/neurolief/mockServiceWorker.js",
//     // },
//   });
// }

Amplify.configure({
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
  },
  API: {
    endpoints: [
      {
        name: 'brains',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
