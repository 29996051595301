export const checkoutText = {
  // header: `It looks like <span class="nowrap">Doxycycline treatment</span> may be right for you!`,
  header: `It looks like <span class="treatment-name">Doxycycline treatment</span> may be right for you!`,
  body: `Your doctor consult will be provided by Beluga Health, a telemedicine provider that serves patients across the country. Your $30 payment goes directly to Beluga Health and covers the cost of your doctor consult. If medically appropriate, treatment may be prescribed to you. The doctor will contact you within the next 24 hours (often times much sooner) via email and text message to complete your consultation.`,
  footer: `In order to verify your identity, please upload a picture of your government-issued photo ID.`,
  payment: `Your doctor consult will be provided by Beluga Health, a telemedicine provider that serves patients across the country. Your $30 payment goes directly to Beluga Health and covers the cost of your doctor consult. If medically appropriate, treatment may be prescribed to you.`,
};

export const introPage = {
  header: `The following questions will help our physicians determine if you’re eligible for treatment with doxycycline for post-exposure prophylaxis (PEP) for STI prevention. Your responses will remain confidential and will be used solely for medical evaluation purposes.`,
  footer: `Beluga Health is a US licensed telemedicine clinic.`,
};

export const paymentSuccess = {
  header: `Thank you! Your information was submitted successfully.`,
  body: `A doctor will now review your intake form. The doctor will contact you within the next 24 hours (often times much sooner) via email and text message to complete your consultation.`,
  footer: `Beluga Health is a US licensed telemedicine clinic.`,
};

export const dataSecure = {
  header:
    "Your personal information is securely transmitted via HIPAA compliant transfer to the Beluga Health medical team.",
};
